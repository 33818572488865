.productItem {
  &:hover {
    .actionList {
      & > li {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .actionList {
    opacity: 1 !important;
    visibility: visible !important;
    right: 10px !important;

    & > li {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(10px, 0, 0);
      transition: 0.5s all ease 0s;

      &.visible {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
