.ReactModal__Overlay .ReactModal__Content {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  opacity: 0;
  transform: translateX(-100px);
}

input[type='number'].hide-control::-webkit-outer-spin-button,
input[type='number'].hide-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].hide-control {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}
