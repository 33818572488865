.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    li {
      list-style: none;
      list-style-type: none;
      display: block;
      height: 36px;
      line-height: 36px;
      padding: 0 12px;
      margin: 5px;
      background: #eee;
      border-radius: 4px;
      transition: 0.5s all ease 0s;

      &.activeLink {
        background: #000;
        color: #fff;

        a {
          color: #fff;
        }
      }

      &:hover {
        background: #ddd;
      }
    }
  }
}
