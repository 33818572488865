.reply {
  border: 1px solid #999;
  margin: 20px 0 10px;
  height: 44px;
  border-radius: 4px;
  line-height: 44px;
  overflow: hidden;
  display: flex;
  padding: 0 0 0 14px;

  .content {
    width: calc(100% - 44px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 100px;
  }

  b {
    margin-right: 5px;
  }

  .close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    color: red;
    text-align: center;
    display: block;
    cursor: pointer;
  }
}
