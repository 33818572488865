.single-product-box {
  .product-image {
    .product-image-link {
      position: relative;
      padding-top: 113.3%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .product-content {
    h3 {
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 20px;
        height: 40px;
        -webkit-line-clamp: 2;
      }
    }
  }
}

@media only screen and (min-width: 767px) {
  .row.products-row-view {
    .single-product-box {
      .product-image {
        width: 40%;

        .product-image-link {
          position: relative;

          img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .product-content {
        flex: 1;
      }
    }
  }
}
