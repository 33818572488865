.girdViewList {
  .girdViewItem {
    span {
      border: 1px solid #ddd !important;
      background: #fff !important;
    }

    &.selected {
      span {
        border: 1px solid #222 !important;
        background: #222 !important;
      }
    }
  }

  .girdViewItemSwitch {
    span {
      background: #ddd !important;

      &:before {
      }
    }

    &.selected {
      span {
        background: #222 !important;
        &:before {
        }
      }
    }
  }
}
