#user-menu-dropdown .dropdown-menu {
  top: calc(100% - 2px) !important;
}
#user-menu-dropdown:hover .dropdown-menu {
  display: block;
}

#user-menu-dropdown .dropdown-item {
  cursor: pointer;
  text-transform: capitalize !important;
}
